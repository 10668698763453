// extracted by mini-css-extract-plugin
export var menuModal = "GlobalMenu-module--menuModal--HBmHR";
export var menuModal__inner = "GlobalMenu-module--menuModal__inner--2wRPa";
export var menuModal__ui = "GlobalMenu-module--menuModal__ui--3spyw";
export var menuModal__title = "GlobalMenu-module--menuModal__title--18Mhl";
export var menuModal__grid = "GlobalMenu-module--menuModal__grid--2hvXb";
export var menuModalPrimaryList = "GlobalMenu-module--menuModalPrimaryList--28p3H";
export var menuModalPrimaryList__sound = "GlobalMenu-module--menuModalPrimaryList__sound--Uwtm0";
export var largeButton = "GlobalMenu-module--large-button--ynqR_";
export var menuModal__news = "GlobalMenu-module--menuModal__news--26Jx6";
export var social_icons = "GlobalMenu-module--social_icons--uhd32";
export var opacityUp = "GlobalMenu-module--opacity-up--e0rPe";
export var spStageSelect = "GlobalMenu-module--spStageSelect--1SgHC";
export var linkLineloop = "GlobalMenu-module--link-lineloop--BqQxN";
export var keyGnav = "GlobalMenu-module--key-gnav--3FxEx";