import React, { useCallback, useContext } from "react"
import * as css from "./GlobalMenuButton.module.scss"
import { useEvents } from "../../hooks"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

type GlobalMenuButtonProps = Readonly<{
  invertColor?: boolean
  isPositionRelative?: boolean
}>

const GlobalMenuButton: React.FC<GlobalMenuButtonProps> = ({ invertColor }) => {
  const classInvertColor = invertColor ? css.rootInvert : css.rootNormal
  const events = useEvents()
  const dispatch = useContext(GlobalDispatchContext)

  const doEmitModalOpen = useCallback(() => {
    events.emit("playSE", `se2`)
    dispatch({ type: "GLOBAL_MENU_ON" })
  }, [events])

  return (
    <button
      className={css.root + ` guide-home-menu ` + classInvertColor}
      onClick={doEmitModalOpen}
    >
      MENU
    </button>
  )
}

export default GlobalMenuButton
