import React, { ChangeEvent, useEffect, useState } from "react"
import * as css from "./SearchExhibitors.module.scss"
import {
  loadExhibitors,
  Exhibitor,
  ExhibitorGenre,
  ExhibitorTag,
  ExhibitorCategory,
} from "../../api"
import { FormattedMessage } from "react-intl"
import SearchExhibitorsList from "./SearchExhibitorsList"
import { useEvents } from "../../hooks"
import KeywordSearchForm from "./KeywordSearchForm"
import { useDebouncedCallback } from "use-debounce"
import GuideTour from "../helper/GuideTour"

type SortKey = "name" | "country" | "category" | "stage"

type SortType = {
  type: string
  name: string
  name_ja: string
  key: SortKey
}

type SearchExhibitorProps = {
  lang: string
  cancelCallBack: () => void
  execCallBack: () => void
}

const sortTypes: SortType[] = [
  {
    type: "stage",
    name: "Stage",
    name_ja: "ステージ別",
    key: "stage",
  },
  {
    type: "alphabetical",
    name: "a-Z",
    name_ja: "ブース名順",
    key: "name",
  },
  { type: "country", name: "Country", name_ja: "国別", key: "country" },
  {
    type: "category",
    name: "Category",
    name_ja: "カテゴリー別",
    key: "category",
  },
]

const SearchExhibitors = (prop: SearchExhibitorProps) => {
  // JSONからロードした出店者リスト
  const [exhibitors, setExhibitors] = useState<Exhibitor[]>([])

  // フィルターされた出店者リスト
  const [visibleExhibitors, setVisibleExhibitors] = useState<Exhibitor[]>([])

  // 出店者リストから抽出したジャンルのリスト
  const [genres, setGenres] = useState<ExhibitorGenre[]>([])

  // 出店者リストから抽出したタグのリスト
  const [tags, setTags] = useState<ExhibitorTag[]>([])

  // 出店者リストから抽出したジャンルのリスト
  const [categories, setCategories] = useState<ExhibitorCategory[]>([])

  // フィルター条件
  const [filters, setFilters] = useState<string[]>([])

  // ソート方法
  const [sortKey, setSortKey] = useState<SortKey>("stage")

  // お気に入りモードかどうか
  const [isFavoriteMode, setIsFavoriteMode] = useState(false)

  const events = useEvents()

  // ソート条件を変更する
  const onChangeSort = (e: any) => {
    const sortType = sortTypes.find(s => s.type === e.currentTarget.value)
    if (!sortType) {
      return
    }
    setSortKey(sortType.key)
  }

  // フィルター条件を変更する
  const onChangeFiltersDebounced = useDebouncedCallback(
    (e: ChangeEvent<HTMLFormElement>) => {
      const inputText = e.target.value
      setFilters(inputText.split(" "))
    },
    300
  )

  // お気に入りモードのトグル
  const toggleFavoriteMode = () => {
    setIsFavoriteMode(!isFavoriteMode)
  }

  // コンポーネントのマウント時に出店者リストをロードする
  useEffect(() => {
    loadExhibitors().then(_exhibitors => {
      const exhibitors = JSON.parse(JSON.stringify(_exhibitors))
      setExhibitors(exhibitors)

      // ジャンルのリストを抽出する
      let extractedGenres = new Map<string, ExhibitorGenre>()
      for (const exhibitor of exhibitors) {
        for (const genre of exhibitor.genre) {
          extractedGenres.set(genre.id, genre)
        }
      }
      setGenres(Array.from(extractedGenres.values()))

      // タグのリストを抽出する
      const extractedTags = new Map<string, ExhibitorTag>()
      for (const exhibitor of exhibitors) {
        for (const tag of exhibitor.tag) {
          extractedTags.set(tag.id, tag)
        }
      }

      let tagResult = Array.from(extractedTags.values())
      tagResult = tagResult.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      })
      setTags(tagResult)

      // カテゴリーのリスト抽出
      const extractedCategories = new Map<string, ExhibitorCategory>()
      for (const exhibitor of exhibitors) {
        extractedCategories.set(exhibitor.category.id, exhibitor.category)
      }

      setCategories(Array.from(extractedCategories.values()))
    })
  }, [])

  // 出店者リスト、フィルター条件およびソート方法が変化したら、表示する出店者リストを更新する
  useEffect(() => {
    // お気に入りIDのハッシュを作成
    const isFavored: Record<string, boolean> = {}
    if (isFavoriteMode) {
      const favoriteStr = localStorage.getItem("favorite") // ローカルストレージ favorite取得
      let favorites = favoriteStr ? JSON.parse(favoriteStr) : []
      for (const fav of favorites) {
        isFavored[fav] = true
      }
    }

    // フィルターする
    if (filters.length === 0 && !isFavoriteMode) {
      setVisibleExhibitors(exhibitors)
      return
    }

    let result: Exhibitor[] = []
    for (const exhibitor of exhibitors) {
      if (isFavoriteMode) {
        if (!isFavored[exhibitor.id]) {
          continue
        }
      }

      // 選択中のジャンルをすべて持っているブースのみ表示
      let hit = true

      for (const keyword of filters) {
        if (keyword === "") {
          break
        }
        // 出展者名で検索
        if (!exhibitor.searchWords.includes(keyword.toUpperCase())) {
          hit = false
          break
        }
      }
      if (hit) {
        result.push(exhibitor)
      }
    }

    setVisibleExhibitors(result)
  }, [exhibitors, filters, sortKey, isFavoriteMode])

  return (
    <div>
      <GuideTour
        lang={prop.lang}
        type={`SEARCH_MENU`}
        delay={1000}
        disableScrolling={true}
      />

      <div className={css.content} data-motion="up-modal">
        <div data-motion="up-inner-1">
          <button
            onClick={() => {
              events.emit("playSE", `se2`)
              prop.cancelCallBack()
            }}
            className={css.content__close}
          >
            <span>
              <FormattedMessage id="cancel" />
            </span>
          </button>

          <header className={css.header}>
            <h2 className={css.header__title}>
              SEARCH EXHIBITORS ({visibleExhibitors.length})
            </h2>
          </header>
          <nav className={css.filters}>
            <div className={css.filters__search + ` guide-search-menu-input`}>
              <KeywordSearchForm
                lang={prop.lang}
                onChangeFilters={(e: ChangeEvent<HTMLFormElement>) => {
                  onChangeFiltersDebounced(e)
                }}
                genres={genres}
                tags={tags}
              />
            </div>
            <div className={css.filters__sub}>
              <div
                className={css.filters__sub__sort + ` guide-search-menu-sort`}
              >
                {/* ソート種別を選択するselect要素 */}
                <label htmlFor="sort">
                  <i className="tabficon-sort" />
                </label>
                <select name="sort" id="sort" onChange={onChangeSort}>
                  {sortTypes.map(sortType => (
                    <option key={sortType.type} value={sortType.type}>
                      {prop.lang === `ja` ? sortType.name_ja : sortType.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={css.filters__sub__fav + ` guide-search-menu-fav`}>
                <input
                  type="checkbox"
                  id={`favoriteCheckbox`}
                  onChange={() => {
                    toggleFavoriteMode()
                  }}
                />
                <label htmlFor={`favoriteCheckbox`}>
                  <FormattedMessage id="showFavoriteBooths" />
                </label>
              </div>
            </div>
          </nav>

          {/* フィルターされた出店者を描画 */}
          <div className={css.content__list}>
            <SearchExhibitorsList
              exhibitors={visibleExhibitors}
              sortKey={sortKey}
              lang={prop.lang}
              doClickCallBack={id => {
                prop.execCallBack()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchExhibitors
