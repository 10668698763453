import { useLocation } from "@reach/router"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import * as css from "./GlobalMenu.module.scss"
import LanguageSwitcher from "../LanguageSwitcher"
import ToggleSound from "../ToggleSound"
import SelectStageMenu from "../SelectStageMenu"
import SearchExhibitorsTrigger from "../search/SearchExhibitorsTrigger"
import { Language, LanguageSwitcherData } from "../../commonTypes"
import { useEvents } from "../../hooks"
import * as commonUI from "../../styles/CommonUi.module.scss"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

import GlobalMenuLargeLink from "./GlobalMenuLargeLink"
import SpStageSelect from "./SpStageSelect"

type GlobalMenuProps = {
  headerState: "isMenuOpen" | "isMenuClosed"
  lang: Language
  langs: LanguageSwitcherData[]
  onClose: () => void
}

/**
 * グローバルメニュー用コンポーネントです
 * @param props
 */
const GlobalMenu: React.FC<GlobalMenuProps> = props => {
  const events = useEvents()
  const dispatch = useContext(GlobalDispatchContext)
  const langDir = props.lang === `en` ? "/en" : ""
  const location = useLocation()
  const regex = /(\/en)?\/([a-z]+)(\/)?/i
  const stageName = location.pathname.replace(regex, "$2")
  const [isFrontPage, setIsFrontpage] = useState(true)

  useEffect(() => {
    const isFront: boolean =
      location.pathname === "/" ||
      location.pathname === "/en" ||
      location.pathname === "/en/"
    setIsFrontpage(isFront)
  }, [setIsFrontpage])

  const doStageSelected = useCallback(
    stageType => {
      events.emit("stageSelect", stageType)
      navigate(`${langDir}/${stageType}/`)

      // LoadingMaskが表示されるのを待つ
      setTimeout(() => {
        props.onClose()
      }, 1000)
    },
    [events, props.onClose]
  )

  const doCloseMenu = useCallback(
    e => {
      events.emit("playSE", `se2`)
      props.onClose()
    },
    [props]
  )

  useEffect(() => {
    events.on("openVendor", doCloseMenu)

    return () => {
      events.off("openVendor", doCloseMenu)
    }
  }, [events, doCloseMenu])

  return (
    <div className={css.menuModal} data-header-state={props.headerState}>
      <div className={css.menuModal__inner}>
        <div className={css.menuModal__ui}>
          <button className={commonUI.GlobalMenuTrigger} onClick={doCloseMenu}>
            CLOSE
          </button>
        </div>

        <h2 className={css.menuModal__title}>
          <a href={`${langDir}/`}>
            TOKYO ART BOOK FAIR 2021 <br />
            ONLINE VENUE
          </a>
        </h2>
        <nav>
          <div className={css.menuModal__grid}>
            <SelectStageMenu
              lang={props.lang}
              callback={doStageSelected}
              mode="half"
            />
          </div>
          <div className={css.menuModal__grid}>
            <SearchExhibitorsTrigger lang={props.lang} largeSize={true} />

            <ul className={css.menuModalPrimaryList}>
              {!isFrontPage ? (
                <li className={css.spStageSelect} data-location={stageName}>
                  <p>STAGE SELECT</p>
                  <SpStageSelect href={`${langDir}/`} />
                </li>
              ) : (
                <li className={css.spStageSelect} />
              )}

              <li className={css.largeButton}>
                <GlobalMenuLargeLink
                  href={`https://spatial.chat/s/tabf2021`}
                  target={`_blank`}
                  onClickCallBack={() => {
                    events.emit("toggleAudio", false, true)
                    dispatch({ type: "SOUND_OFF" })
                  }}
                  title={`TABF SPATIAL CHAT`}
                  bannerSrc={`menu/GlobalMenu__spatialchat.jpg`}
                  iconClassName={`tabficon-video`}
                >
                  {props.lang === "ja" ? (
                    <p>
                      お店の人もお客さんも
                      <br className="show-sp" />
                      みんなが集まるチャット空間
                    </p>
                  ) : (
                    <p>
                      A chat space where both exhibitors
                      <br className="show-sp" />
                      and visitors can gather together <br />
                      <small>
                        ＊We will be using “SPATIAL CHAT” a chat tool available
                        for everyone!
                      </small>
                    </p>
                  )}
                </GlobalMenuLargeLink>
              </li>

              <li className={css.largeButton}>
                <GlobalMenuLargeLink
                  title={`TABF x ZOZOVILLA`}
                  iconClassName={`tabficon-blank`}
                  href={`https://zozo.jp/search/?p_tpcsid=1532983`}
                  target={`_blank`}
                  onClickCallBack={() => {
                    events.emit("toggleAudio", false, true)
                    dispatch({ type: "SOUND_OFF" })
                  }}
                >
                  {props.lang === "ja" ? (
                    <p>
                      海外拠点のTABF出展者によるアートブックが並ぶ特設サイト
                    </p>
                  ) : (
                    <p>
                      A special website with a exclusive lineup of art books by
                      our overseas participants <br />
                      It runs in conjunction with the ART BOOK VENDING MACHINE
                      exhibit at the Offline venue!
                    </p>
                  )}
                </GlobalMenuLargeLink>
              </li>

              <li className={css.largeButton}>
                <GlobalMenuLargeLink
                  title={`VABF KIOSK ONLINE STORE`}
                  iconClassName={`tabficon-blank`}
                  href={`https://vabf.shop/`}
                  target={`_blank`}
                  onClickCallBack={() => {
                    events.emit("toggleAudio", false, true)
                    dispatch({ type: "SOUND_OFF" })
                  }}
                >
                  {props.lang === "ja" ? (
                    <p>TABFが制作するZINEやグッズを取り扱うオンラインストア</p>
                  ) : (
                    <p>An online shop for ZINEs and goods by TABF</p>
                  )}
                </GlobalMenuLargeLink>
              </li>

              <li className={css.largeButton}>
                <GlobalMenuLargeLink
                  title={`TABF EXHIBITORS' EVENTS`}
                  iconClassName={`tabficon-blank`}
                  href={`https://tokyoartbookfair.com/events/`}
                  target={`_blank`}
                  onClickCallBack={() => {
                    events.emit("toggleAudio", false, true)
                    dispatch({ type: "SOUND_OFF" })
                  }}
                >
                  {props.lang === "ja" ? (
                    <p>TABF会期中に出展者が開催するイベント一覧</p>
                  ) : (
                    <p>
                      List of events organized by the exhibitors during the fair
                      period.
                    </p>
                  )}
                </GlobalMenuLargeLink>
              </li>

              <li>
                <a
                  className={"link__blank"}
                  target={`_blank`}
                  href={`https://tokyoartbookfair.com/`}
                >
                  <i className={`tabficon-blank`} />
                  <span>ABOUT TABF</span>
                </a>

                <div className={css.social_icons}>
                  <a href={`https://twitter.com/tabf_info`} target={`_blank`}>
                    <i aria-label="Twitter" className={"icon-twitter_circle"} />
                  </a>
                  <a
                    href={`https://www.facebook.com/tokyoartbookfair`}
                    target={`_blank`}
                  >
                    <i aria-label="Facebook" className={"icon-facebook"} />
                  </a>
                  <a
                    href={`https://www.instagram.com/tokyoartbookfair/`}
                    target={`_blank`}
                  >
                    <i aria-label="Facebook" className={"icon-instagram"} />
                  </a>
                </div>
              </li>

              <li>
                <LanguageSwitcher langs={props.langs} />

                <div className={css.menuModalPrimaryList__sound}>
                  <ToggleSound />
                </div>
              </li>
            </ul>

            <p className={css.menuModal__news}>
              <a
                href="https://tokyoartbookfair.com/"
                className={"link__blank link__blank--ja"}
                target={`_blank`}
              >
                <i className={`tabficon-blank`} />
                <span>
                  {props.lang === `ja`
                    ? "10月28日–31日は東京都現代美術館でTOKYO ART BOOK FAIR 2021が開催"
                    : "TOKYO ART BOOK FAIR 2021 will be held at the Museum of Contemporary Art Tokyo from October 28 to 31."}
                </span>
              </a>
            </p>

            {/*
            <p>
              これまでのご来場者: <Counter />人
            </p>
            <ul>
              <li>
                <Link to={`${langDir}/terms-of-use/`}>TERMS OF USE</Link>
              </li>
              <li>
                <Link to={`${langDir}/privacy-policy/`}>PRIVACY POLICY</Link>
              </li>
            </ul>
            */}
          </div>
        </nav>
      </div>
    </div>
  )
}
export default GlobalMenu
