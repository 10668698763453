import React, { useCallback } from "react"
import { Language, StageType } from "../commonTypes"
import "./SelectStageMenu.scss"
import Image from "./image"
import { useEvents } from "../hooks"
type SelectStageMenuProps = {
  lang: Language | undefined
  mode: "full" | "half"
  callback: (stageType: StageType) => void | undefined
}

const SelectStageMenu: React.FC<SelectStageMenuProps> = ({
  lang = "ja",
  mode = "full",
  callback = () => {},
}) => {
  const events = useEvents()
  const btnClick = useCallback(
    (stageType: StageType) => () => {
      switch (stageType) {
        case "carrot":
          events.emit("playSE", `se/1-2_a`)
          break
        case "radish":
          events.emit("playSE", `se/1-1_a`)
          break
        case "onion":
          events.emit("playSE", `se/1-3_a`)
          break
        case "potato":
          events.emit("playSE", `se/1-7_a`)
          break
        case "garlic":
          events.emit("playSE", `se/1-5_a`)
          break
        case "turnip":
          events.emit("playSE", `se/1-4_a`)
          break
      }

      callback(stageType)
    },
    [callback]
  )

  return (
    <div className={`selectStageMenu`} data-mode={mode}>
      {lang === "ja" ? (
        <p className={`selectStageMenu__guide`}>ステージを選択してください</p>
      ) : (
        <p className={`selectStageMenu__guide`}>Select the Stage</p>
      )}

      <button className={`newBtn newBtn-carrot`} onClick={btnClick(`carrot`)}>
        <div className={`newBtn__img def`}>
          <Image filename="menu/carrot.png" alt="" />
        </div>
        <div className={`newBtn__img hover`}>
          <Image filename="menu/carrot--hover.png" alt="" />
        </div>
        <div className="hotspot">
          <div className="hotspot__inner"></div>
        </div>
        <p className={`label`}>CARROT</p>
      </button>

      <button className={`newBtn newBtn-radish`} onClick={btnClick(`radish`)}>
        <div className={`newBtn__img def`}>
          <Image filename="menu/radish.png" alt="" />
        </div>
        <div className={`newBtn__img hover`}>
          <Image filename="menu/radish--hover.png" alt="" />
        </div>
        <div className="hotspot">
          <div className="hotspot__inner"></div>
        </div>
        <p className={`label`}>RADISH</p>
      </button>

      <button className={`newBtn newBtn-onion`} onClick={btnClick(`onion`)}>
        <div className={`newBtn__img def`}>
          <Image filename="menu/onion.png" alt="" />
        </div>
        <div className={`newBtn__img hover`}>
          <Image filename="menu/onion--hover.png" alt="" />
        </div>
        <div className="hotspot">
          <div className="hotspot__inner"></div>
        </div>
        <p className={`label`}>ONION</p>
      </button>

      <button className={`newBtn newBtn-turnip`} onClick={btnClick(`turnip`)}>
        <div className={`newBtn__img def`}>
          <Image filename="menu/turnip.png" alt="" />
        </div>
        <div className={`newBtn__img hover`}>
          <Image filename="menu/turnip--hover.png" alt="" />
        </div>
        <div className="hotspot">
          <div className="hotspot__inner"></div>
        </div>
        <p className={`label`}>TURNIP</p>
      </button>

      <button className={`newBtn newBtn-potato`} onClick={btnClick(`potato`)}>
        <div className={`newBtn__img def`}>
          <Image filename="menu/potato.png" alt="" />
        </div>
        <div className={`newBtn__img hover`}>
          <Image filename="menu/potato--hover.png" alt="" />
        </div>
        <div className="hotspot">
          <div className="hotspot__inner"></div>
        </div>
        <p className={`label`}>POTATO</p>
      </button>

      <button className={`newBtn newBtn-garlic`} onClick={btnClick(`garlic`)}>
        <div className={`newBtn__img def`}>
          <Image filename="menu/garlic.png" alt="" />
        </div>
        <div className={`newBtn__img hover`}>
          <Image filename="menu/garlic--hover.png" alt="" />
        </div>
        <div className="hotspot">
          <div className="hotspot__inner"></div>
        </div>
        <p className={`label`}>GARLIC</p>
      </button>

      <a
        href={`https://zozo.jp/search/?p_tpcsid=1532983`}
        target={`_blank`}
        className={`newBtn newBtn-pumpkin`}
        onMouseDown={() => {
          events.emit("playSE", `se/1-6_a`)
        }}
      >
        <div className={`newBtn__img def`}>
          <Image filename="menu/pumpkin.png" alt="" />
        </div>
        <div className={`newBtn__img hover`}>
          <Image filename="menu/pumpkin--hover.png" alt="" />
        </div>
        <div className="hotspot">
          <div className="hotspot__inner"></div>
        </div>
        <p className={`label`}>TABF ZOZO STORE</p>
      </a>
    </div>
  )
}

export default SelectStageMenu
