import React from "react"
import * as css from "./PaymentIcons.module.scss"

type PaymentIconsProps = {
  amazon: boolean
  zozo: boolean
  paypal: boolean
  showDisabledIcon?: boolean
}

const PaymentIcons: React.FC<PaymentIconsProps> = props => {
  if (props.showDisabledIcon) {
    return (
      <div className={css.pay}>
        <i
          className={`${css.pay__amazon} tabficon-logo-amazonpay ${
            !props.amazon && css.disabled
          }`}
        />
        <i
          className={`tabficon-logo-paypal ${!props.paypal && css.disabled}`}
        />
        <i className={`tabficon-logo-zozo ${!props.zozo && css.disabled}`} />
      </div>
    )
  } else {
    return (
      <div className={css.pay}>
        {props.amazon && (
          <i
            className={`${css.pay__amazon} tabficon-logo-amazonpay`}
            title={`AmazonPay is available`}
          />
        )}
        {props.paypal && (
          <i
            className={`${css.pay__paypal} tabficon-logo-paypal`}
            title={`PayPal is available`}
          />
        )}
        {props.zozo && (
          <i
            className={`${css.pay__zozo} tabficon-logo-zozo`}
            title={`Available at Zozo.`}
          />
        )}
      </div>
    )
  }
}

export default PaymentIcons
