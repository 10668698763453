import React from "react"
import * as css from "./GlobalMenuLargeLink.module.scss"
import Image from "../image"
type PropTypes = {
  href: string
  target?: string
  onClickCallBack?: () => void
  title: string
  bannerSrc?: string
  iconClassName?: string
}

const GlobalMenuLargeLink: React.FC<PropTypes> = ({
  href,
  target,
  onClickCallBack,
  title,
  children,
  bannerSrc,
  iconClassName,
}) => {
  return (
    <a
      href={href}
      className={css.base}
      target={target}
      onClick={onClickCallBack}
    >
      <div className={css.base__inner}>
        {iconClassName && <i className={iconClassName} />}
        <span>{title}</span>
        {children}
      </div>

      {bannerSrc && (
        <div className={css.banner}>
          <Image filename={bannerSrc} alt="" />
        </div>
      )}
    </a>
  )
}

export default GlobalMenuLargeLink
