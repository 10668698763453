module.exports = {
  hello: "Howdy!!!",
  enter: "Enter!",
  enterWithoutSound: "Enter without sound",
  dummyMessage1: "Dummy Message No.1",
  usefullinks: "USEFULL LINKS",
  deepLDescription: "A useful AI translation tool",
  souryoKeisan: "International Shipping Cost Calculator",
  souryoKeisanDescription: "International shipping is calculated here!",
  bbsMessage: "Feel free to post on the VABF bulletin board (BBS)!",
  kioskMessage: "SOS! VABF Crowdfunding",
  museumTokyo: "MUSEUM OF CONTEMPORARY ART, TOKYO",
  honkawamachi: "BOOKS AND RIVERS AND CITY",
  honkawamachiMessage:
    "Book-themed events are held in the Fukagawa area near the Museum of Contemporary Art Tokyo.",
  searchBooths: "Search Booths",
  showFavoriteBooths: "Show favorites only",
  showAllBooths: "Show All",
  apply: "Apply",
  cancel: "Cancel",
  reset: "Reset",
  useCookie:
    "We use Google Analytics to provide the visitors with an enhanced online experience. If you agree to use cookies, please click “Agree.” Please note that if you choose to disable them you may lose some site features. For more information, please refer to our Privacy Policy.",
  accept: "I accept",
  reject: "Reject",
  readMore: "Read more",
  showDetail: "See details",
  contact: "Contact",
  showOtherItem: "Show other item",
  showBooth: "Visit the booth",
  leave: "Good bye!",
  simpleHtmlVersion: "Exhibitors (HTML Version)",
  recommendChrome: "We recommend using Google Chrome for this site.",
  recommendPc: "This site is better viewed on a PC.",
  visitTheSite: "Visit the site",
  ok: "OK",
  OK: "OK",
}
