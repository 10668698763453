import React, { useEffect } from "react"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider } from "react-intl"
import { useEvents } from "../hooks"
import { Language, Location } from "../commonTypes"
import messagesJa from "../data/messages/ja"
import messagesEn from "../data/messages/en"

import Header from "../components/Header"
import SEO from "../components/SEO"
import OptinConsent from "../components/OptinConsent"
import RecommendBrowser from "../components/RecommendBrowser"
import * as css from "./index.module.scss"

/**
 * アンマウント時にイベントを発行する
 */
const CheckMuteBgm = () => {
  const events = useEvents()
  useEffect(() => {
    const url = window.location.pathname
    const webGLPages = [`/`, `/exhibitors/`]
    let isWebGLPage = false
    for (let i = 0; i < webGLPages.length; i += 1) {
      if (url === webGLPages[i] || url === `/en` + webGLPages[i]) {
        isWebGLPage = true
        break
      }
    }
    if (!isWebGLPage) {
      events.emit("stopBGM")
    }
  }, [events])

  return <></>
}

export type LayoutProps = {
  children: React.ReactNode
  lang: Language
  location: Location
  title?: string
  description?: string
  ogImage?: string
  show3dLayer?: boolean
  invertColor?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  lang,
  children,
  location,
  title = "",
  description = "",
  ogImage,
  show3dLayer = false,
  invertColor = false,
}) => {
  const i18nMessages = lang === "en" ? messagesEn : messagesJa

  const classCover3DLayer = show3dLayer ? "" : css.cover
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        const langKey = getCurrentLangKey(langs, defaultLangKey, url)
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
        const langsMenu = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url)
        ).map(item => ({
          ...item,
          link: item.link.replace(`/${defaultLangKey}/`, "/"),
        }))

        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <div>
              <div className={classCover3DLayer + ` layout-cover`}>
                <SEO
                  title={title}
                  lang={langKey}
                  description={description}
                  ogImage={ogImage}
                />
                <CheckMuteBgm />
                <RecommendBrowser />
                <Header
                  siteTitle={data.site.siteMetadata.title}
                  lang={langKey}
                  langs={langsMenu}
                  invertColor={invertColor}
                />
                <main> {children} </main>
                <OptinConsent />
              </div>
            </div>
          </IntlProvider>
        )
      }}
    />
  )
}

export default Layout
