import * as css from "./KeywordSearchForm.module.scss"
import React, { useEffect, useRef } from "react"
/**
 * todo: 未実装 キーワードでの絞り込み
 * @param prop
 * @constructor
 */
const KeywordSearchForm = (prop: any) => {
  const strPlaceholder =
    prop.lang === "ja" ? "出展者名、キーワードなど" : "Name, keywords..."
  const textInput = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    textInput.current?.focus()
  },[])

  return (
    <div className={css.search}>
      <form onChange={prop.onChangeFilters}>
        <input
          type="text"
          placeholder={strPlaceholder}
          className={`searchKeyword`}
          ref={textInput}
          onKeyDown={e => {
            if (e.code === "Enter") {
              e.preventDefault();
              return false
            }
          }}
        />
        <i className={`icon-search`} />
      </form>
    </div>
  )
}

export default KeywordSearchForm
