import React from "react"
import * as css from "./SearchExhibitors.module.scss"
import { useEvents } from "../../hooks"
import { Exhibitor, ExhibitorCategory } from "../../api"
import { decodeHTMLEntities } from "../../utils/decodeHTMLEntities"
import noImage from "../../images/no-image.png"
import PaymentIcons from "../exhibitor/PaymentIcons"
import { normalizeInitial } from "../../utils/normalizeInitial"
import { sortExhibitorsByKeys } from "../../utils/sortExhibitorsByKeys"
import { Link } from "gatsby"

type SearchExhibitorsListProps = {
  exhibitors: Exhibitor[]
  sortKey: string
  lang: string
  doClickCallBack: (id: string) => void
}

const sortKeysJa = {
  stage: [`name_kana`, `stage`],
  name: [`name_kana`, `initial`],
  country: [`name_kana`, `country`],
  category: [`name_kana`, `category`],
}

const sortKeysEn = {
  stage: [`name_en`, `stage`],
  name: [`name_en`],
  country: [`name_en`, `country`],
  category: [`name_en`, `category`],
}

const SearchExhibitorsList = (prop: SearchExhibitorsListProps) => {
  const events = useEvents()
  const lang = prop.lang ? prop.lang : "en"

  /**
   * ベンダーページへ移動
   * @param id
   */
  const moveToVendor = (id: string) => (e: MouseEvent) => {
    if (e.metaKey || e.shiftKey) {
      return
    } else {
      events.emit("openVendor", id, prop.lang)
      prop.doClickCallBack && prop.doClickCallBack(id)
      e.preventDefault()
      return false
    }
  }

  const normalizeCountry = (src: string) => {
    src = src.toUpperCase()
    return src
  }

  const exportGroups = (
    groups: {
      groupTitle: string
      contents: Exhibitor[]
    }[],
    ignoreCountry: boolean = false,
    ignoreStage: boolean = false
  ) => {
    // console.log(groups)

    // 分類を出力
    let output = []
    let counter = 0

    for (const group of groups) {
      let list = []

      for (const exhibitor of group.contents) {
        list.push(
          <li
            key={counter + `-` + exhibitor.id}
            className={css.listItem}
            data-stage={exhibitor.stage.toLowerCase()}
          >
            <Link
              to={
                lang === `ja`
                  ? `/exhibitors/${String(exhibitor.id)}/`
                  : `en/exhibitors/${String(exhibitor.id)}/`
              }
              className={css.listItem__wrap}
              onClick={moveToVendor(String(exhibitor.id))}
            >
              <div className={css.listItem__wrap__icon}>
                {exhibitor.icon ? (
                  <img src={exhibitor.icon} alt={``} />
                ) : (
                  <img src={noImage} alt={``} />
                )}
              </div>
              <div className={css.listItem__wrap__name}>
                {lang === `en` && exhibitor.name_en ? (
                  <span>{decodeHTMLEntities(exhibitor.name_en)}</span>
                ) : (
                  <span>{decodeHTMLEntities(exhibitor.name)}</span>
                )}
              </div>
              <div
                className={
                  css.listItem__wrap__payment + ` guide-search-menu-payment`
                }
              >
                <PaymentIcons
                  paypal={exhibitor.payment.includes(3)}
                  amazon={exhibitor.payment.includes(2)}
                  zozo={exhibitor.payment.includes(1)}
                  showDisabledIcon={true}
                />
              </div>
              <div className={css.listItem__wrap__country}>
                {!ignoreCountry && exhibitor.country && exhibitor.country}

                {!ignoreCountry && !ignoreStage && `,`}
                {!ignoreStage && exhibitor.stage}
              </div>
            </Link>
          </li>
        )
        counter += 1
      }

      let groupIcon = null
      if (prop.sortKey === `stage`) {
        groupIcon = (
          <i className={css.exhibitorListHead__icon}>
            <img
              src={`/stageIcons/${group.groupTitle.toLowerCase()}.png`}
              alt=""
            />
          </i>
        )
      }

      output.push(
        <div key={counter}>
          <h2 className={css.exhibitorListHead}>
            {groupIcon}
            {group.groupTitle}
          </h2>
          <ul className={css.exhibitorList}>{list}</ul>
        </div>
      )
      counter += 1
    }

    // not found を表示
    if (output.length <= 0) {
      output.push(
        <div className={css.notfound} key={`notfound`}>
          <p className={css.notfound__eye}>&#128064;</p>
          {lang === `ja` ? (
            <p className={css.notfound__text}>何も見つかりませんでした</p>
          ) : (
            <p className={css.notfound__text}>Could not find the booth</p>
          )}
        </div>
      )
    }

    return <>{output}</>
  }

  /**
   * アルファベティカル
   */
  const getNameSortedContent = () => {
    let sortedExhibitors = null

    if (lang === `ja`) {
      sortedExhibitors = sortExhibitorsByKeys(prop.exhibitors, sortKeysJa.name)
    } else {
      sortedExhibitors = sortExhibitorsByKeys(prop.exhibitors, sortKeysEn.name)
    }

    let alphabetGroups = []
    let currentGroup: {
      groupTitle: string
      contents: Exhibitor[]
    } = {
      groupTitle: "",
      contents: [],
    }

    // アルファベッドで分類
    for (const exhibitor of sortedExhibitors) {
      let initial = exhibitor.initial
      if (lang === `ja`) {
        initial = normalizeInitial(exhibitor.initial)
      } else {
        initial = normalizeInitial(exhibitor.initial_en)
      }
      if (currentGroup.groupTitle !== initial) {
        currentGroup = {
          groupTitle: initial,
          contents: [],
        }
        alphabetGroups.push(currentGroup)
      }

      currentGroup.contents.push(exhibitor)
    }
    return exportGroups(alphabetGroups)
  }

  /**
   * 国別
   */
  const getCountrySortedContent = () => {
    const sortedExhibitors = sortExhibitorsByKeys(
      prop.exhibitors,
      lang === `ja` ? sortKeysJa.country : sortKeysEn.country
    )

    let groups = []
    let currentGroup: {
      groupTitle: string
      contents: Exhibitor[]
    } = {
      groupTitle: "",
      contents: [],
    }

    // アルファベッドで分類
    for (const exhibitor of sortedExhibitors) {
      let country = normalizeCountry(exhibitor.country)

      if (currentGroup.groupTitle !== country) {
        currentGroup = {
          groupTitle: country,
          contents: [],
        }
        groups.push(currentGroup)
      }
      currentGroup.contents.push(exhibitor)
    }

    return exportGroups(groups, true)
  }

  /**
   * カテゴリーソート
   */
  const getCategorySortedContent = () => {
    const sortedExhibitors = sortExhibitorsByKeys(
      prop.exhibitors,
      lang === `ja` ? sortKeysJa.category : sortKeysEn.category
    )

    let groups = []
    let currentGroup: {
      groupTitle: string
      contents: Exhibitor[]
    } = {
      groupTitle: "",
      contents: [],
    }

    for (const exhibitor of sortedExhibitors) {
      let category: ExhibitorCategory = exhibitor.category
      let title = lang === `ja` ? category.name_ja : category.name_en

      if (currentGroup.groupTitle !== title) {
        currentGroup = {
          groupTitle: title,
          contents: [],
        }
        groups.push(currentGroup)
      }
      currentGroup.contents.push(exhibitor)
    }

    return exportGroups(groups)
  }

  /**
   * ステージソート
   */
  const getStageSortedContent = () => {
    const sortedExhibitors = sortExhibitorsByKeys(
      prop.exhibitors,
      lang === `ja` ? sortKeysJa.stage : sortKeysEn.stage
    )
    let groups = []
    let currentGroup: {
      groupTitle: string
      contents: Exhibitor[]
    } = {
      groupTitle: "",
      contents: [],
    }

    // アルファベッドで分類
    for (const exhibitor of sortedExhibitors) {
      let stage = exhibitor.stage

      if (currentGroup.groupTitle !== stage) {
        currentGroup = {
          groupTitle: stage,
          contents: [],
        }
        groups.push(currentGroup)
      }
      currentGroup.contents.push(exhibitor)
    }

    return exportGroups(groups, false, true)
  }

  if (prop.sortKey === "name") {
    return getNameSortedContent()
  } else if (prop.sortKey === "category") {
    return getCategorySortedContent()
  } else if (prop.sortKey === "stage") {
    return getStageSortedContent()
  } else {
    return getCountrySortedContent()
  }
}

export default SearchExhibitorsList
