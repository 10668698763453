module.exports = {
  hello: "こんにちは!!!",
  enter: "入場する",
  enterWithoutSound: "サウンドなしで入場する",
  dummyMessage1: "ダミーメッセージ1",
  usefullinks: "お役立ちリンク集",
  deepLDescription: "万能のAI翻訳サイト",
  souryoKeisan: "海外送料計算サイト",
  souryoKeisanDescription: "海外の方への発送はこちらで計算！",
  bbsMessage: "VABFの掲示板(BBS)気軽に書き込もう！",
  kioskMessage: "SOS! VABFのクラウドファンディング",
  museumTokyo: "東京都現代美術館",
  honkawamachi: "本と川と街",
  honkawamachiMessage:
    "東京都現代美術館近く、深川エリアで本をテーマにしたイベントを開催中。",
  searchBooths: "ブース検索",
  showFavoriteBooths: "お気に入りのみ",
  showAllBooths: "すべて表示",
  apply: "適用",
  cancel: "キャンセル",
  reset: "リセット",
  useCookie:
    "よりよいウェブ体験をお届けするために、私たちは Google アナリティクスを使用しています。クッキーの使用に同意する場合は、「同意する」をクリックしてください。無効にする場合は、一部のサイト機能が失われる場合がありますのでご注意ください。詳しくはプライバシーポリシーをご確認ください。",
  accept: "同意する",
  reject: "無効にする",
  readMore: "もっとみる",
  showDetail: "詳細をみる",
  contact: "お問い合わせ",
  showOtherItem: "別のアイテムをみる",
  showBooth: "ブースをみる",
  leave: "さよなら",
  simpleHtmlVersion: "出展者リスト シンプル版",
  recommendChrome: "当サイトは Google Chrome での閲覧を推奨します。",
  recommendPc: "当サイトは PC から、より快適にご覧いただけます。",
  visitTheSite: "ウェブサイトを見る",
  ok: "OK",
  OK: "OK",
}
