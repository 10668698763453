import React, { useCallback, useContext, useState } from "react"
import * as css from "./SearchExhibitorsTrigger.module.scss"
import { useEvents } from "../../hooks"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

export type SearchExhibitorsTriggerProps = {
  lang: string
  largeSize?: boolean
  invertColor?: boolean
}

const SearchExhibitorsTrigger = (props: SearchExhibitorsTriggerProps) => {
  const events = useEvents()
  const dispatch = useContext(GlobalDispatchContext)

  /**
   * 検索モーダルの呼び出しコールバック
   */
  const doEmitSearchOpen = useCallback(() => {
    events.emit("playSE", `se2`)
    dispatch({ type: "SEARCH_MODE_ON" })
  }, [dispatch])

  const cssColor = props.invertColor ? css.colorInvert : css.colorNormal

  if (props.largeSize) {
    const buttonLabel =
      props.lang === "ja" ? "出展者名、キーワードなど" : "Name, keywords..."

    return (
      <div className={css.largeButton}>
        <button
          onClick={() => {
            doEmitSearchOpen()
          }}
        >
          <i className={`icon-search`} />
          {buttonLabel}
        </button>
      </div>
    )
  }

  return (
    <>
      <button
        className={`icon-search ${css.button} ${cssColor}`}
        onClick={() => {
          doEmitSearchOpen()
          events.emit("playSE", `se2`)
        }}
      >
        <span>ブースを探す</span>
      </button>
    </>
  )
}

export default SearchExhibitorsTrigger
