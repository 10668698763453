//components/image.js

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                fixed(width: 600) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `}
    /*
      サイズFIXしたい時は上記childImageSharp {...}の中を以下のように変更
              sizes(maxWidth: 300) {
                ...GatsbyImageSharpSizes
              }
    */
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }
      //const imageSizes = image.node.childImageSharp.sizes; ←サイズFIXしたい時
      /*<Img alt={props.alt} sizes={imageSizes} /> ←サイズFIXしたい時 */
      if (props.type === "fixed") {
        return (
          <Img
            fixed={image.node.childImageSharp.fixed}
            alt={props.alt}
            loading="eager"
          />
        )
      } else {
        return (
          <Img
            fluid={image.node.childImageSharp.fluid}
            alt={props.alt}
            loading="eager"
          />
        )
      }
    }}
  />
)
export default Image

// ref: https://qiita.com/atomyah/items/e6aebf0a0abe3d488787
