import React, { useContext, useEffect, useState } from "react"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import * as css from "./SpStageSelect.module.scss"

type SpStageSelectTypes = {
  href: string
}

const SpStageSelect: React.FC<SpStageSelectTypes> = ({ href }) => {
  const state = useContext(GlobalStateContext)
  const [stageName, setStageName] = useState("")

  useEffect(() => {
    // ステージの情報がなければ出店者情報からステージを取得する
    const stage_ = state.stage ? state.stage : state.exhibitor.stage
    setStageName(stage_.toLowerCase())
  }, [state.exhibitor, state.stage, setStageName])

  return (
    <a href={href} className={css.base} data-location={stageName}>
      <div className={css.figure}>
        <img src={`/stageIcons/${stageName}.png`} alt="" />
      </div>
      <div className={css.body}>{stageName.toUpperCase()}</div>
    </a>
  )
}

export default SpStageSelect
