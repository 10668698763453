import React, { useCallback, useContext, useEffect } from "react"
import * as css from "./ToggleSound.module.scss"
import { useEvents } from "../hooks"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { getRandomBGM } from "../webgl/utils"

export type ToggleSoundProps = {
  invertColor?: boolean
}

/**
 * サイト内の音のオンオフさせます
 */
const ToggleSound: React.FC<ToggleSoundProps> = ({ invertColor = false }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const events = useEvents()

  useEffect(() => {
    const isSoundOn = localStorage.getItem("VABF_USER_SOUND") === "ON"
    // console.log(`ローカルストレージのサウンドは`, isSoundOn)
    // console.log(`グローバルステートのサウンドは`, state.useSound)
    if (isSoundOn && !state.useSound && state.webGlLoaded) {
      events.emit("toggleAudio", true, true)
      dispatch({ type: "SOUND_ON" })
      // console.log(`システム側で音をオンにします`)
    }
  }, [dispatch, state])

  const doToggle = useCallback(() => {
    if (state.useSound) {
      events.emit("toggleAudio", false, true)
      localStorage.setItem("VABF_USER_SOUND", "OFF")
      dispatch({ type: "SOUND_OFF" })
    } else {
      events.emit("toggleAudio", true, true)
      localStorage.setItem("VABF_USER_SOUND", "ON")
      dispatch({ type: "SOUND_ON" })

      // すでにステージにはいってたら曲を描ける
      if (state.stage) {
        events.emit("playBGM", getRandomBGM())
      }
    }
    dispatch({ type: "INCREMENT_TEST_COUNT" })
  }, [dispatch, state, events])

  const cssSoundState = state.useSound ? css.soundActive : css.soundDeactive
  const cssInvert = invertColor ? css.soundColorInvert : css.soundColorNormal

  return (
    <button
      onClick={doToggle}
      className={css.btn + " " + cssSoundState + " " + cssInvert}
    ></button>
  )
}
export default ToggleSound
