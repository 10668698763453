export function isExpiredWithLocalStorage(
  key: string,
  duration: number
): boolean {
  let isExpired = false

  if (!localStorage) {
    return isExpired
  }

  const lastFiredTime = localStorage.getItem(key)

  if (lastFiredTime) {
    const sabun =
      new Date().getTime() - new Date(Number(lastFiredTime)).getTime()

    if (sabun > duration) {
      isExpired = true
    }
  } else {
    isExpired = true
  }

  return isExpired
}
