export function normalizeInitial(src: string) {
  src = src.toUpperCase()
  if (src.match(/[0-9]/)) {
    return "0-9"
  } else if (
    src.match(
      /[々〇〻\u3400-\u9FFF\uF900-\uFAFF]|[\uD840-\uD87F][\uDC00-\uDFFF]/
    )
  ) {
    return "漢"
    // } else if (src.match(/[\u3041-\u3096]|[\u30A1-\u30FA]/)) {
    //   return "かな"
  }
  return src
}
