import React, { useState, useEffect, useCallback, useContext } from "react"
import * as css from "./GuideTour.module.scss"
import "./joyrideStyleOverride.scss"
import Joyride from "react-joyride"
import { isExpiredWithLocalStorage } from "../../utils/isExpiredWithLocalStorage"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

type MessageItemType = Readonly<{
  target: string
  content: string
  disableBeacon?: boolean
}>

function getMessages(lang: string, type: string): MessageItemType[] {
  if (type === "MESSAGE_MODAL") {
    return [
      {
        target: ".guide-message-showBooth",
        disableBeacon: true,
        content:
          lang === "ja"
            ? "気になるブースに立ち寄ってみましょう"
            : "Stop by booths that interest you!",
      },
      {
        target: ".guide-message-next-prev",
        content:
          lang === "ja"
            ? "ここからお隣のブースへ移動できます"
            : "You can also jump over to the next both from here.",
      },
    ]
  } else if (type === "EXHIBITOR_DETAIL") {
    return [
      {
        target: ".guide-exhibitor-fav",
        disableBeacon: true,
        content:
          lang === "ja"
            ? "お気に入りは検索ページから確認できます"
            : "You can check your favorited booths from the search page",
      },
      {
        target: ".guide-exhibitor-table-link",
        content:
          lang === "ja"
            ? "気になる商品をチェックしましょう"
            : "Check out the items for more details",
      },
      {
        target: ".guide-exhibitor-next-prev",
        content:
          lang === "ja"
            ? "ここからお隣のブースへ移動できます"
            : "You can also jump over to the next both from here.",
      },
    ]
  } else if (type === "SEARCH_MENU") {
    return [
      {
        target: ".guide-search-menu-input",
        disableBeacon: true,
        content:
          lang === "ja"
            ? "キーワードで出展者を検索できます"
            : "Search for exhibitors using keywords",
      },
      {
        target: ".guide-search-menu-sort",
        disableBeacon: true,
        content:
          lang === "ja"
            ? "表示順を変更できます"
            : "You can change the order of the list",
      },
      {
        target: ".guide-search-menu-fav",
        disableBeacon: true,
        content:
          lang === "ja"
            ? "お気に入りしたブースだけ表示します"
            : "It will list up the booths you favorited",
      },
      {
        target: ".guide-search-menu-payment",
        disableBeacon: true,
        content:
          lang === "ja"
            ? "ZOZO, Amazon Pay, Paypal 対応のブースがひと目でわかります"
            : "You can see at a glance which booths support ZOZO, Amazon Pay, and Paypal",
      },
    ]
  }

  return [
    {
      target: ".guide-home-sound",
      disableBeacon: true,
      content:
        lang === "ja"
          ? "サウンド ON だとより楽しめます"
          : "Turn on the sound for more fun!",
    },
    {
      target: ".guide-home-search",
      content:
        lang === "ja"
          ? "ここから出展者が検索できます"
          : "You can search the different exhibitors here",
    },
    {
      target: ".guide-home-boothArea",
      content:
        lang === "ja"
          ? "ブースを選択して話しかけましょう！"
          : "Choose a booth and start chatting!",
    },
  ]
}

type Props = Readonly<{
  lang: string
  type: string
  delay: number
  disableScrolling?: boolean
}>

const GuideTour: React.FC<Props> = ({
  lang,
  type = "HOME",
  delay = 0,
  disableScrolling = false,
}) => {
  const [steps, setSteps] = useState(getMessages(lang, type))
  const state = useContext(GlobalStateContext)
  const [showGide, setShowGide] = useState(false)

  // 12時間あける
  const HOURS = 24
  useEffect(() => {
    const isExpired = isExpiredWithLocalStorage(
      `GUIDE_LAST_FIRED_TIME__${type}`,
      HOURS * 60 * 60 * 1000
    )
    let timeoutId = 0
    if (delay > 0) {
      timeoutId = window.setTimeout(() => {
        setShowGide(isExpired)
      }, delay)
    } else {
      setShowGide(isExpired)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [
    setShowGide,
    state.showExhibitorModal,
    state.showGlobalMenu,
    state.webGlLoaded,
  ])

  const doSaveLocalStorage = useCallback(() => {
    const isExpired = isExpiredWithLocalStorage(
      `GUIDE_LAST_FIRED_TIME__${type}`,
      30 * 60 * 1000
    )

    if (isExpired) {
      localStorage.setItem(
        `GUIDE_LAST_FIRED_TIME__${type}`,
        new Date().getTime().toString()
      ) // ローカルストレージに時間を記録
    }
  }, [type])

  if (!state.webGlLoaded) {
    return <></>
  }

  if (type === "HOME" && (state.showExhibitorModal || state.showGlobalMenu)) {
    return <></>
  }

  if (type === "EXHIBITOR_DETAIL" && state.showGlobalMenu) {
    return <></>
  }

  if (
    type === "MESSAGE_MODAL" &&
    (state.showExhibitorModal || state.showGlobalMenu)
  ) {
    return <></>
  }

  if (type === "GLOBAL_MENU" && !state.showGlobalMenu) {
    return <></>
  }

  return (
    <div className={css.base + ` ` + showGide ? css.isShow : css.isHide}>
      {showGide && (
        <div>
          <Joyride
            steps={steps}
            showProgress={true}
            continuous={true}
            callback={e => {
              if (e.action === "update" && e.type === "tooltip") {
                doSaveLocalStorage()
              }
            }}
            disableScrolling={disableScrolling}
            styles={{
              options: {
                primaryColor: "#f00",
              },
              buttonNext: {
                borderRadius: 0,
              },
              tooltip: {
                borderRadius: 0,
                color: `#000`,
                padding: `10px`,
              },
              buttonClose: {
                display: "none",
              },
            }}
          />
        </div>
      )}
    </div>
  )
}
export default GuideTour
