import React from "react"
import { LanguageSwitcherData } from "../commonTypes"
import * as css from "./LanguageSwitcher.module.scss"

type LanguageSwitcherProps = {
  langs: LanguageSwitcherData[]
}

const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const links = props.langs.map(lang => (
    <a href={lang.link} key={lang.langKey}>
      {!lang.selected && <>{lang.langKey}</>}
    </a>
  ))

  return (
    <div className={css.base} data-motion="lang">
      {links}
    </div>
  )
}
export default LanguageSwitcher
