import { Exhibitor } from "../api"

/**
 * 出店者リストをクローンし、ソートして返す。
 */
export function sortExhibitorsByKeys(
  exhibitors: Exhibitor[],
  keys: string[]
): Exhibitor[] {
  const sortedExhibitors = exhibitors.slice()

  keys.forEach((key: string, i: number) => {
    if (key === "category") {
      sortedExhibitors.sort((a: any, b: any) =>
        a.category.name_en < b.category.name_en ? -1 : 1
      )
    } else {
      sortedExhibitors.sort((a: any, b: any) =>
        a[key].toUpperCase() < b[key].toUpperCase() ? -1 : 1
      )
    }
  })

  return sortedExhibitors
}
