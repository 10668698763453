import { Link } from "gatsby"
import React, { useCallback, useContext } from "react"
import "./Header.scss"
import GlobalMenu from "./menu/GlobalMenu"
import { Language, LanguageSwitcherData } from "../commonTypes"
import Helmet from "react-helmet"
import { useEvents } from "../hooks"
import SearchExhibitors from "./search/SearchExhibitors"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import GlobalMenuButton from "./menu/GlobalMenuButton"
import StageSelectModal from "./menu/StageSelectModal"

type HeaderProps = Readonly<{
  siteTitle: string
  lang: Language
  langs: LanguageSwitcherData[]
  invertColor: boolean
}>

const Header: React.FC<HeaderProps> = ({
  siteTitle = "",
  lang,
  langs,
  invertColor = false,
}) => {
  const events = useEvents()
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const doEmitModalOpen = useCallback(() => {
    events.emit("playSE", `se2`)
    dispatch({ type: "GLOBAL_MENU_ON" })
  }, [events])

  const doEmitModalClose = useCallback(() => {
    dispatch({ type: "GLOBAL_MENU_OFF" })
  }, [events])

  return (
    <header
      className={
        state.showGlobalMenu ? "header isMenuOpen" : "header isMenuClosed"
      }
    >
      {state.showGlobalMenu && (
        <Helmet
          htmlAttributes={{
            class: "gnav--open",
          }}
        />
      )}

      <h1 className={`header__title`}>
        <Link to="/"> {siteTitle} </Link>
      </h1>

      <GlobalMenuButton invertColor={invertColor} />

      <div className={`header__global-menu`} data-motion="up-modal">
        <GlobalMenu
          lang={lang}
          langs={langs}
          headerState={state.showGlobalMenu ? "isMenuOpen" : "isMenuClosed"}
          onClose={doEmitModalClose}
        />
      </div>

      {state.showSearch && (
        <SearchExhibitors
          lang={lang}
          cancelCallBack={() => {
            dispatch({ type: "SEARCH_MODE_OFF" })
          }}
          execCallBack={() => {
            dispatch({ type: "SEARCH_MODE_OFF" })
          }}
        />
      )}
      {state.showStageSelect && (
        <StageSelectModal
          lang={lang}
          onClose={() => {
            dispatch({ type: "SEARCH_MODE_OFF" })
          }}
        />
      )}
    </header>
  )
}

export default Header
