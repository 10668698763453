import React, { useCallback, useContext } from "react"
import { navigate } from "gatsby"
import SelectStageMenu from "../SelectStageMenu"
import { Language, StageType } from "../../commonTypes"
import { useEvents } from "../../hooks"
import * as css from "./StageSelectModal.module.scss"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"
import { useUrlForLang } from "../../hooks/language"

type propsType = {
  lang: Language
  onClose: () => void
}

/**
 * グローバルメニュー用コンポーネントです
 * @param props
 */
const StageSelectModal: React.FC<propsType> = props => {
  const events = useEvents()
  const urlForLang = useUrlForLang()
  const dispatch = useContext(GlobalDispatchContext)

  const stageSelectedCallback = useCallback(
    (stageType: StageType) => {
      events.emit("stageSelect", stageType)
      navigate(urlForLang(`${stageType}/`))
      // LoadingMaskが表示されるのを待つ
      setTimeout(() => {
        dispatch({ type: "STAGE_SELECT_OFF" })
      }, 1000)
    },
    [urlForLang]
  )
  const doClose = useCallback(() => {
    dispatch({ type: "STAGE_SELECT_OFF" })
  }, [dispatch])

  return (
    <div className={css.base} data-motion="up-modal">
      <div className={css.inner} data-motion="up-inner-1">
        <button className={css.closeButton} onClick={doClose}>
          CLOSE
        </button>
        <SelectStageMenu
          lang={props.lang}
          callback={stageSelectedCallback}
          mode={"full"}
        />
      </div>
    </div>
  )
}
export default StageSelectModal
